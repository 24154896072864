function PrivacyPolitic() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-200 to-gray-100">
      <div className="bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 text-white py-16 pt-32">
        <h1 className="text-4xl font-bold text-center">Política de Privacidad</h1>
      </div>

      <div className="max-w-7xl mx-auto bg-white rounded-xl shadow-xl p-8">
        <h2 className="text-3xl font-bold colorFontBlue mb-8 text-center border-b pb-4">
          Política de Privacidad y Manejo de Datos
        </h2>

        <div className="grid md:grid-cols-2 gap-12">
          {/* Columna izquierda */}
          <div className="space-y-8">
            <section className="hover:bg-gray-50 p-6 rounded-lg transition duration-300">
              <h2 className="text-2xl font-bold colorFontRed mb-4 border-b border-gray-200 pb-2">1. Información General</h2>
              <div className="space-y-4">
                <div>
                  <h3 className="text-xl font-semibold colorFontBlue mb-3">1.1 Responsable del Tratamiento de Datos</h3>
                  <p className="text-gray-600 leading-relaxed">
                  VELIMAQ VEHICULOS Y MAQUINARIAS SOCIEDAD ANÓNIMA CERRADA (en adelante, "la Empresa"), con domicilio en Mza. Ch Lote. 3 - Villa Santa Maria - Carabayllo - Lima, Perú, es el responsable del tratamiento de los datos personales que nos proporcione.
                  </p>
                </div>
                <div>
                  <h3 className="text-xl font-semibold colorFontBlue mb-3">1.2 Base Legal</h3>
                  <p className="text-gray-600 leading-relaxed">
                    La presente política se encuentra fundamentada en la Ley N.° 29733, Ley de Protección de Datos Personales del Perú, y su Reglamento aprobado por Decreto Supremo N.° 003-2013-JUS.
                  </p>
                </div>
              </div>
            </section>
            
            <section className="hover:bg-gray-50 p-6 rounded-lg transition duration-300">
              <h2 className="text-2xl font-bold colorFontRed mb-4 border-b border-gray-200 pb-2">2. Datos Personales Recopilados</h2>
              <div className="space-y-4">
                <div>
                  <h3 className="text-xl font-semibold colorFontBlue mb-3">2.1 Tipos de Datos</h3>
                  <ul className="list-disc pl-6 text-gray-600 space-y-2">
                    <li>Datos de identificación personal</li>
                    <li>Datos de contacto</li>
                    <li>Datos de navegación web</li>
                    <li>Información proporcionada voluntariamente por el usuario</li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-xl font-semibold colorFontBlue mb-3">2.2 Fuentes de Obtención</h3>
                  <ul className="list-disc pl-6 text-gray-600 space-y-2">
                    <li>Directamente del titular</li>
                    <li>A través de formularios web</li>
                    <li>Mediante interacciones en el sitio web</li>
                  </ul>
                </div>
              </div>
            </section>

            <section className="hover:bg-gray-50 p-6 rounded-lg transition duration-300">
              <h2 className="text-2xl font-bold colorFontRed mb-4 border-b border-gray-200 pb-2">3. Finalidad del Tratamiento de Datos</h2>
              <h3 className="text-xl font-semibold colorFontBlue mb-3">3.1 Objetivos Principales</h3>
              <ul className="list-disc pl-6 text-gray-600 space-y-2">
                <li>Proveer servicios solicitados</li>
                <li>Gestionar solicitudes y consultas</li>
                <li>Mejorar la experiencia del usuario</li>
                <li>Enviar información relacionada con nuestros servicios</li>
              </ul>
              <h3 className="text-xl font-semibold colorFontBlue mb-3">3.2 Limitaciones</h3>
              <ul className="list-disc pl-6 text-gray-600 space-y-2">
                <li>No utilizar los datos para fines diferentes a los declarados</li>
                <li>Solicitar consentimiento previo para cualquier uso adicional</li>
              </ul>
            </section>

            <section className="hover:bg-gray-50 p-6 rounded-lg transition duration-300">
              <h2 className="text-2xl font-bold colorFontRed mb-4 border-b border-gray-200 pb-2">4. Consentimiento del Titular</h2>
              <h3 className="text-xl font-semibold colorFontBlue mb-3">4.1 Consentimiento Informado</h3>
              <p className="text-gray-600 leading-relaxed">
                El titular de los datos personales otorga su consentimiento libre, previo, expreso, inequívoco e informado para el tratamiento de sus datos.
              </p>
              <h3 className="text-xl font-semibold colorFontBlue mb-3">4.2 Revocación del Consentimiento</h3>
              <p className="text-gray-600 leading-relaxed">
                El titular puede revocar su consentimiento en cualquier momento mediante comunicación escrita o digital.
              </p>
            </section>

            <section className="hover:bg-gray-50 p-6 rounded-lg transition duration-300">
              <h2 className="text-2xl font-bold colorFontRed mb-4 border-b border-gray-200 pb-2">5. Derechos del Titular</h2>
              <h3 className="text-xl font-semibold colorFontBlue mb-3">5.1 Derechos ARCO</h3>
              <ul className="list-disc pl-6 text-gray-600 space-y-2">
                <li>Acceder a sus datos personales</li>
                <li>Rectificar información inexacta</li>
                <li>Cancelar sus datos</li>
                <li>Oponerse al tratamiento de sus datos</li>
              </ul>
              <h3 className="text-xl font-semibold colorFontBlue mb-3">5.2 Procedimiento de Ejercicio</h3>
              <ul className="list-disc pl-6 text-gray-600 space-y-2">
                <li>Enviar solicitud por escrito</li>
                <li>Adjuntar documento de identidad</li>
                <li>Especificar el derecho que desea ejercer</li>
              </ul>
            </section>

            
          </div>

          {/* Columna derecha */}
          <div className="space-y-8">
          <section className="hover:bg-gray-50 p-6 rounded-lg transition duration-300">
              <h2 className="text-2xl font-bold colorFontRed mb-4 border-b border-gray-200 pb-2">6. Seguridad de los Datos Personales</h2>
              <h3 className="text-xl font-semibold colorFontBlue mb-3">6.1 Medidas de Seguridad</h3>
              <ul className="list-disc pl-6 text-gray-600 space-y-2">
                <li>Protocolos de seguridad tecnológica</li>
                <li>Restricciones de acceso a datos</li>
                <li>Cifrado de información sensible</li>
              </ul>
              <h3 className="text-xl font-semibold colorFontBlue mb-3">6.2 Confidencialidad</h3>
              <p className="text-gray-600 leading-relaxed">
                Nos comprometemos a mantener la confidencialidad de los datos.
              </p>
            </section>
            <section className="hover:bg-gray-50 p-6 rounded-lg transition duration-300">
              <h2 className="text-2xl font-bold colorFontRed mb-4 border-b border-gray-200 pb-2">7. Transferencia y Comunicación de Datos</h2>
              <h3 className="text-xl font-semibold colorFontBlue mb-3">7.1 Principios</h3>
              <ul className="list-disc pl-6 text-gray-600 space-y-2">
                <li>Con consentimiento del titular</li>
                <li>Cumpliendo la normativa vigente</li>
                <li>Garantizando niveles adecuados de protección</li>
              </ul>
              <h3 className="text-xl font-semibold colorFontBlue mb-3">7.2 Supuestos de Transferencia</h3>
              <ul className="list-disc pl-6 text-gray-600 space-y-2">
                <li>Requerimientos legales</li>
                <li>Prestación de servicios</li>
                <li>Colaboraciones empresariales autorizadas</li>
              </ul>
            </section>

            <section className="hover:bg-gray-50 p-6 rounded-lg transition duration-300">
              <h2 className="text-2xl font-bold colorFontRed mb-4 border-b border-gray-200 pb-2">8. Almacenamiento de Datos</h2>
              <h3 className="text-xl font-semibold colorFontBlue mb-3">8.1 Periodo de Conservación</h3>
              <ul className="list-disc pl-6 text-gray-600 space-y-2">
                <li>Durante el tiempo necesario para cumplir la finalidad</li>
                <li>Según los plazos establecidos en la normativa</li>
              </ul>
              <h3 className="text-xl font-semibold colorFontBlue mb-3">8.2 Eliminación de Datos</h3>
              <ul className="list-disc pl-6 text-gray-600 space-y-2">
                <li>Se cumpla la finalidad de su tratamiento</li>
                <li>El titular solicite su cancelación</li>
                <li>Expire el plazo legal de conservación</li>
              </ul>
            </section>

            <section className="hover:bg-gray-50 p-6 rounded-lg transition duration-300">
              <h2 className="text-2xl font-bold colorFontRed mb-4 border-b border-gray-200 pb-2">9. Contacto y Consultas</h2>
              <div className="space-y-2">
                <p className="text-gray-600 leading-relaxed">
                  <span className="font-medium">Email:</span> notificación@velimaq.com.pe
                </p>
                <p className="text-gray-600 leading-relaxed">
                  <span className="font-medium">Teléfono:</span> 947 232 440
                </p>
                <p className="text-gray-600 leading-relaxed">
                  <span className="font-medium">Dirección:</span> Jr. Casuarinas 231
                </p>
              </div>
            </section>

            <section className="hover:bg-gray-50 p-6 rounded-lg transition duration-300">
              <h2 className="text-2xl font-bold colorFontRed mb-4 border-b border-gray-200 pb-2">10. Modificaciones a la Política</h2>
              <p className="text-gray-600 leading-relaxed">
                Nos reservamos el derecho de modificar la presente política, notificando previamente a los usuarios.
              </p>
            </section>

            <section className="hover:bg-gray-50 p-6 rounded-lg transition duration-300">
              <h2 className="text-2xl font-bold colorFontRed mb-4 border-b border-gray-200 pb-2">11. Legislación Aplicable</h2>
              <p className="text-gray-600 leading-relaxed">
                Esta política se rige por:
                <ul className="list-disc pl-6 text-gray-600 space-y-2">
                  <li>Ley N.° 29733</li>
                  <li>Decreto Supremo N.° 003-2013-JUS</li>
                  <li>Normativa complementaria peruana de protección de datos</li>
                </ul>
              </p>
            </section>

            <section className="hover:bg-gray-50 p-6 rounded-lg transition duration-300">
              <h2 className="text-2xl font-bold colorFontRed mb-4 border-b border-gray-200 pb-2">12. Disposición Final</h2>
              <p className="text-gray-600 leading-relaxed">
                La Empresa garantiza el cumplimiento de la Ley N.° 29733 y el respeto de los derechos fundamentales de privacidad y autodeterminación informativa.
              </p>
            </section>
          </div>
        </div>

        <footer className="mt-16 pt-6 border-t border-gray-200">
          <div className="text-center">
            <p className="text-gray-600 font-semibold">
              © Derechos Reservados {new Date().getFullYear()} - Velimaq
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default PrivacyPolitic;
