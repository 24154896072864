import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/solid";
import { LINK } from "../../acces/acces";
import Product from "./Product";
import Hero from "./Hero";

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectProduct, setSelectProduct] = useState(true);
  const [chosenProduct, setChosenProduct] = useState({
    id: 1,
    nombre: "Moto Sierra",
    url: "motoniveladora-john-deere-620g",
    precio: 120000,
    estado: "En Venta",
    categoria: "MotoNiveladora",
    descripcion: "POTENCIA: 210 HP\nCAPACIDAD DE HOJA: 3660 mm\nPESO OPERACIONAL: 18500 KG\nAÑO DE FABRICACION: 2020\nHOROMETRO: 750 horas",
    imagen: "https://velimaq.smartaccesorios.shop/zimages/1/Motoniveladora%20Princiapl.webp, https://velimaq.smartaccesorios.shop/zimages/1/princ.webp, https://velimaq.smartaccesorios.shop/zimages/1/0.webp, https://velimaq.smartaccesorios.shop/zimages/1/1.webp",
    info1: "",
    info2: ""
  });
  const { url } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(LINK)
      .then((response) => {
        setProducts(response.data);
        if (url) {
          const product = response.data.find(p => p.url === url);
          if (product) {
            setSelectProduct(false);
            setChosenProduct(product);
          } else {
            setSelectProduct(true); // Reset si no se encuentra el producto
          }
        } else {
          setSelectProduct(true); // Reset cuando url es undefined (ruta /productos)
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error fetching the products!", error);
        setLoading(false);
      });
  }, [url]); // Dependencia url para detectar cambios en la ruta

  function changeSelectProduct(product) {
    if (product) {
      navigate(`/productos/${product.url}`);
      setSelectProduct(false);
      setChosenProduct(product);
    } else {
      navigate('/productos');
      setSelectProduct(true);
    }
  }

  return (
    <>
    <Hero />
    <div className="container mx-auto pt-10 pb-10">
      {selectProduct ? (
        <div className="flex flex-col items-start">
          <h1 className="text-3xl font-bold mb-10">Todos los productos</h1>
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-6">
            {loading ? (
              Array.from({ length: 10 }).map((_, index) => (
                <div key={index} className="border rounded-2xl overflow-hidden shadow-lg">
                  <Skeleton height={240} width={240} />
                  <div className="p-4">
                    <Skeleton count={2} />
                    <Skeleton width="60%" height={24} className="mt-4" />
                  </div>
                </div>
              ))
            ) : (
              products.map((product) => (
                <div
                  key={product.id}
                  className="border rounded-2xl overflow-hidden shadow-lg"
                >
                  <div className="relative">
                    <img
                      onClick={() => changeSelectProduct(product)}
                      src={product.imagen.split(",")[0].trim()}
                      alt={product.nombre}
                      className="w-full h-[200px] md:h-[280px] object-cover cursor-pointer"
                    />
                    {product.estado && (
                      <div className="absolute top-0 left-0 bg-red-500 text-white text-sm px-2 py-1">
                        {product.estado}
                      </div>
                    )}
                  </div>
                  <div className="p-3">
                    <h2 className="font-bold text-xl h-16">
                      {product.nombre}
                    </h2>
                    <p className="mt-1 text-lg font-semibold text-green-500 text-right">
                      {product.estado?.toLowerCase().includes('alquiler') ? 'S/.' : '$'}
                      {product.precio.toLocaleString()}
                    </p>
                    <div className="w-full flex items-center justify-between hover:bg-red-600 hover:text-white hover:rounded-full cursor-pointer">
                      <div
                        onClick={() => changeSelectProduct(product)}
                        className="bg-gray-900 text-white px-4 py-1.5 rounded-full text-xs md:text-xs lg:text-sm xl:text-base inline-flex w-full items-center justify-between hover:bg-red-500 hover:text-white"
                      >
                        <span className="mr-3">Más Info</span>
                        <MagnifyingGlassCircleIcon className="h-8 -my-1 w-10" />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      ) : (
        <Product cerrar={setSelectProduct} producto={chosenProduct} />
      )}
    </div>
    </>

    
  );
};

export default ProductList;
