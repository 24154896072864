import React, { useEffect } from "react";

const GoogleTagManager = () => {
  useEffect(() => {
    // Inicializar dataLayer
    window.dataLayer = window.dataLayer || [];

    // Insertar el script principal de GTM
    const script = document.createElement("script");
    script.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-K4H3GX7K');
  `;
    document.head.appendChild(script);
  }, []);

  return (
    <noscript>
      <iframe
        title="Google Tag Manager"
        src="https://www.googletagmanager.com/ns.html?id=GTM-K4H3GX7K"
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
      />
    </noscript>
  );
};

export default GoogleTagManager;
