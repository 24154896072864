import React from 'react';
import { ArrowLeftCircleIcon } from '@heroicons/react/24/solid';
import ProductImagesGallery from './ProductImagesGallery';
import { useNavigate } from 'react-router-dom';

const Product = ({ producto, cerrar }) => {
  const navigate = useNavigate();
  const { nombre, precio, estado, categoria, descripcion, imagen, info1 } = producto;
  const imagenes = imagen.split(',').map((url) => url.trim());

  const handleClick = () => {
    window.open(info1, "_blank");
  };

  const handleBack = () => {
    cerrar(true);
    navigate('/productos');
  };

  return (
    <div className="max-w-4xl mx-auto bg-white  rounded-lg shadow-lg overflow-hidden">
      <div onClick={handleBack} className="cursor-pointer h-12 hover:rounded-full py-3 w-32 flex items-center justify-start hover:bg-red-500 hover:text-white">
        <ArrowLeftCircleIcon className='h-10 w-auto' /> <span className='robotoC font-bold text-xl px-2 '>Regresar</span>
      </div>
      <div className="flex mt-2">
        <div className="w-full sm:w-1/2">
          <div className="carousel h-[300px] md:h-[455px]">
            <ProductImagesGallery images={imagenes} />
          </div>
        </div>
        <div className="pl-9 w-full sm:w-1/2 ">
          <h2 className=" text-3xl font-bold text-gray-800">{nombre}</h2>
          <p className="-mt-3 font-bold text-3xl  text-gray-400">{categoria}</p>
          <p className=" pl-4 text-gray-600 mt-2 font-bold">Estado: {estado}</p>
          <p className="pl-4 text-gray-600 mt-2 font-semibold">
            Precio: {estado?.toLowerCase().includes('alquiler') ? 'S/.' : '$'}
            {precio}
          </p>
          <p className="pl-4 italic text-sm robotoC text-gray-600 mt-4 whitespace-pre-line">{descripcion}</p>
          
        </div>
      </div>
      <button onClick={handleClick} className="rounded-full w-full mt-4 bg-red-600 text-white px-4 py-3  text-xl/3">
            Cotizar - {nombre}
          </button>
    </div>
  );
};

export default Product;
