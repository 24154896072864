// src/components/HomePage.js
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./HomePage.css";
import Hero from "./Hero";
import AboutUs from "./AboutUs";
import Services from "./Services";
import Locations from "./Locations";
import SuccesStories from "./SuccesStories";
import { SuccesImagesApp } from "./SuccesImagesApp";

const HomePage = () => {
  return (
    <>
      <Hero />
      <AboutUs />
      <Services />
      <SuccesStories />
      <Locations />
    </>
  );
};

export default HomePage;
